import React from "react";
import Link from "gatsby-link";

import Layout from "../components/Layout";

// https://www.npmjs.com/package/react-obfuscate
import Obfuscate from "react-obfuscate";

import Clipboard from "react-clipboard.js";

import Spaguffelkasse from "../assets/logo-sparkasse.svg";

class Unterstuetzung extends React.Component {
  constructor() {
    super();

    this.onClipboardSaveSuccess = this.onClipboardSaveSuccess.bind(this);
  }

  componentDidMount() {
    //new ClipboardJS('.clipboardSave');
  }

  onClipboardSaveSuccess(e) {
    //console.info('saved! ' + e.trigger);
    var span = e.trigger.nextSibling;

    span.className = "tick shown";
    setTimeout(() => {
      span.className = "tick";
    }, 1800);
  }

  render() {
    return (
      <Layout pageTitle={`Ihre Unterstützung`}>
        <div className="ihre-unterstuetzung">
          <div className="site-content_inner">
            <h2>Ihre Unterstützung</h2>
            <p>
              {/* Um die Arbeit des Förder&shy;ver&shy;eins zu er&shy;mög&shy;lichen,
            sind wir auf Ihre Unter&shy;stützung an&shy;ge&shy;wiesen, denn der
            Förder&shy;verein finan&shy;ziert sich aus&shy;schließ&shy;lich
            durch die Bei&shy;träge seiner Mit&shy;glieder sowie durch die
            groß&shy;zügigen Spenden von Eltern, Lehrer*innen wie auch vielen
            anderen Personen. */}
              Um die Arbeit des Förder­&shy;ver­eins zu
              er­&shy;mög­&shy;li&shy;chen, sind wir auf Ihre
              Un&shy;ter­&shy;stützung an&shy;­ge­&shy;wiesen, denn der
              För&shy;der­&shy;ver&shy;ein fi&shy;nan­&shy;ziert sich
              aus­&shy;schließ­&shy;lich durch die Bei­&shy;träge seiner
              Mit­g&shy;lieder sowie durch groß­&shy;zügige Spenden.
            </p>
          </div>
          <div className="unterstuetzung-quote">
            <div className="unterstuetzung-quote_content">
              <span className="marks">&rdquo;</span>
              <p>
                Jede Investition in die Schule ist eine Investition in die
                Zukunft unserer Kinder!
              </p>
              <div className="quotee">
                <p>Inês Dabrowski</p>
                <span>
                  ehem. Mitglied des <Link to="/vorstand">Vorstands</Link>
                </span>
              </div>
            </div>
          </div>

          <div className="site-content_inner">
            <p>
              Natürlich können Sie die Arbeit des Förder&shy;vereins auch mit
              einer ein&shy;maligen Spende unter&shy;stützen.
            </p>
            <p>
              Auch jede Hilfe&shy;stel&shy;lung in Form von Mit&shy;arbeit,
              Sach&shy;spenden und/oder Kon&shy;takten ist eben&shy;falls
              will&shy;kommen.
            </p>
            <p>
              Haben Sie Ideen oder An&shy;re&shy;gungen, möchten Sie
              mit&shy;helfen oder Paten&shy;schaften für be&shy;stim&shy;mte
              Projekte über&shy;nehmen? Dann sprechen Sie uns ein&shy;fach an
              oder {/* <a href="#">schreiben eine e-mail</a>! */}
              <Obfuscate
                email="foerderverein@luisen-gymnasium.de"
                headers={{
                  subject: "Mail von freunde-luisengymnasium.de",
                }}>
                schreiben eine E-mail
              </Obfuscate>
              !
            </p>
            <p>
              Spenden und Mit&shy;glieds&shy;beiträge sind steuer&shy;lich
              absetz&shy;bar.
            </p>
          </div>

          <div className="konto site-content_inner">
            <h3>Spendenkonto</h3>
            <div className="konto_table">
              <div className="konto_top">
                <div className="konto_top__left">
                  <p>Stadtsparkasse Düsseldorf</p>
                </div>
                <div className="konto_top__right">
                  <p>
                    <strong>IBAN</strong>
                  </p>
                  <Clipboard
                    className="clipboardSave"
                    data-clipboard-text="DE35300501101005020076"
                    onSuccess={this.onClipboardSaveSuccess}>
                    DE35300501101005020076
                  </Clipboard>
                  <span className="tick">✔️</span>
                </div>
              </div>
              <div className="konto_bottom">
                <div className="konto_bottom__left">&nbsp;</div>
                <div className="konto_bottom__right">
                  <p>
                    <strong>BIC</strong>
                  </p>
                  <Clipboard
                    className="clipboardSave BIC"
                    data-clipboard-text="DUSSDEDDXXX"
                    onSuccess={this.onClipboardSaveSuccess}>
                    DUSSDEDDXXX
                  </Clipboard>
                  <span className="tick">✔️</span>
                </div>
              </div>
              <div className="konto_footer">
                <div className="konto_footer__left">
                  <img src={Spaguffelkasse} alt="sparkasse" />
                </div>
                <div className="konto_footer__right">
                  <p>IBAN und BIC anklicken für Kopie in die Zwischenablage</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Unterstuetzung;
